/**
 * Calculates the elapsed time in week, day, hour and minute format and returns it as an object.
 * 
 * @param {*} elapsedMinutes - elapsed time in minutes
 */
const calculateElapsedTime = (elapsedMinutes) => {
	if (elapsedMinutes < 60) {
		return { min: elapsedMinutes };
	}

	const minutesInDay = 60 * 24;
	if (elapsedMinutes < minutesInDay) {
		const min = elapsedMinutes % 60;
		const hour = Math.floor(elapsedMinutes / 60);
		return { hour, min };
	}

	const minutesInWeek = minutesInDay * 7;
	if (elapsedMinutes < minutesInWeek) {
		const day = Math.floor(elapsedMinutes / minutesInDay);
		const remainedMinutesFromDay = elapsedMinutes % minutesInDay;
		const hour = Math.floor(remainedMinutesFromDay / 60);
		const min = elapsedMinutes % 60;
		return { day, hour, min };
	}

	const week = Math.floor(elapsedMinutes / minutesInWeek);
	const remainedMinutesFromWeek = elapsedMinutes % minutesInWeek;
	const day = Math.floor(remainedMinutesFromWeek / minutesInDay);
	const remainedMinutesFromDay = remainedMinutesFromWeek % minutesInDay;
	const hour = Math.floor(remainedMinutesFromDay / 60);
	const min = elapsedMinutes % 60;
	return {
		week, day, hour, min,
	};
};

/**
 * Creates a readable format of the elapsed minutes using weeks, days, hours and minutes.
 * 
 * @param {*} elapsedMinutes 
 */
export const formatElapsedTime = (elapsedMinutes) => {
	const {
		week, day, hour, min,
	} = calculateElapsedTime(elapsedMinutes);
	let result = '';
	if (week) {
		result += `${week}w`;
	}
	if (day) {
		result += (result !== '' ? ' ' : '');
		result += `${day}d`;
	}
	if (hour) {
		result += (result !== '' ? ' ' : '');
		result += `${hour}h`;
	}
	if (result) {
		if (min) {
			result += ` ${min}min`;
		}
	} else {
		result += `${min}min`;
	}
	return result;
};

/**
 * Format: yyyy/MM/dd hh:mm
 * 
 * @param {*} dateTime - object to format
 */
export const formatDateTime = (dateTime) => {
	return dateTime.getFullYear() + "/" +
		("0" + (dateTime.getMonth() + 1)).slice(-2) + "/" +
		("0" + dateTime.getDate()).slice(-2) + " " +
		("0" + dateTime.getHours()).slice(-2) + ":" +
		("0" + dateTime.getMinutes()).slice(-2);
}

/**
 * Returns the absolute difference in minutes between two Date objects.
 * 
 * @param {*} date1
 * @param {*} date2 
 */
export const diffInMinutes = (date1, date2) => {
	const diff = (date1.getTime() - date2.getTime()) / 1000;
	return Math.abs(Math.round(diff / 60));
};
