import { formatElapsedTime, diffInMinutes } from '../utils/util';
import CONSTANTS from '../utils/constants';

/**
 * Saves a history entry into the card's scope with shared visibility, meaning any user
 * can access this data.
 * Returns a Promise.
 * 
 * @param {*} t - Trello API
 * @param {*} card - Object with two properties: idList and dateLastActivity (retrieved from the API)
 * @param {*} cardHistory - Array containing all the history entries of the card
 */
export const saveNewHistoryEntry = async (t, card, cardHistory) => {
	const entry = {
		list: card.idList,
		since: card.dateLastActivity,
	};
	cardHistory.push(entry);
	return t.set('card', 'shared', CONSTANTS.KEY.HISTORY, cardHistory);
};

/**
 * Called repeatedly after specified time in the cardBadges function.
 * It recalculates and updates the elapsed time on the badge based on the last history entry's date.
 * 
 * @param {*} lastEntryDate - Date object, indicates when was the card moved into its current list
 */
export const refreshTimeOnBadge = lastEntryDate => {
	const elapsedMinutes = diffInMinutes(new Date(Date.now()), lastEntryDate);
	return {
		icon: './calendar.svg',
		text: formatElapsedTime(elapsedMinutes),
		color: 'green',
		refresh: CONSTANTS.REFRESH_INTERVAL,
	};
};

/**
 * This method will be called for all the cards on the board.
 * Returns an array of badges (currently only one) which will appear on the front of them.
 * 
 * @param {*} t - Trello API
 */
const cardBadges = async t => {
	try {
		const [card, cardHistory, disabledListIDs] = await Promise.all([
			t.card('idList', 'dateLastActivity'),
			t.get('card', 'shared', CONSTANTS.KEY.HISTORY, []),
			t.get('board', 'shared', CONSTANTS.KEY.DISABLED_LIST_IDS, [])
		]);
		/**
		 * In case there isn't any saved history for the card, should initialize it.
		 *
		 * Return empty list, since the function will be recalled after the history data is saved.
		 */
		if (cardHistory.length === 0) {
			await saveNewHistoryEntry(t, card, cardHistory);
			return [];
		}
		const lastHistoryEntry = cardHistory[cardHistory.length - 1];
		/**
		 * Need to check whether the card was moved into another list. If it was, a history
		 * entry must be saved for it.
		 *
		 * Must verify that both `idList` and `dateLastActivity` had been changed, because this
		 * function is called twice after a card move:
		 *  1. The `idList` changes, reflecting the card's new list.
		 *  2. The `dateLastActivity` changes to the date when the move had finished.
		 */
		if (lastHistoryEntry.list !== card.idList) {
			if (lastHistoryEntry.since !== card.dateLastActivity) {
				await saveNewHistoryEntry(t, card, cardHistory);
			}
			return [];
		}

		if (disabledListIDs.includes(card.idList)) {
			return [];
		}

		return [
			{
				dynamic: () => refreshTimeOnBadge(new Date(lastHistoryEntry.since)),
			},
		];
	} catch (e) {
		return [];
	}
};

export default cardBadges;
