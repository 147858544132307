import '@babel/runtime/regenerator';
import cardBadges from './card-badge/card-badge';
import cardBackSection from './card-back-section/card-back-section';
import timeTrackingListAction from './list-actions/list-actions';

window.TrelloPowerUp.initialize(
	{
		'card-badges': cardBadges,
		'card-back-section': cardBackSection,
		'list-actions': timeTrackingListAction,
	},
);
