import CONSTANTS from '../utils/constants';
import { formatElapsedTime, formatDateTime, diffInMinutes } from '../utils/util';

/**
 * Returns the HTML page which will be rendered on the back of every card.
 * 
 * @param {*} t - Trello API
 */
const cardBackSection = t => {
	return {
		title: 'Card History',
		icon: './calendar.svg', // Must be a gray icon, colored icons not allowed.
		content: {
			type: 'iframe',
			url: t.signUrl('./history.html'),
		}
	};
}

/**
 * Builds and returns a two dimensional array containing HTML table rows.
 * Each row in the matrix is a group containing all of the history entries for the same list. The first element is
 * always an aggregated one which is collapsible in case there are multiple entries for the actual list. The rest of the
 * elements from the row are those entries that make the aggregated one.
 * 
 * @param {*} t - Trello API
 */
export const createHistoryTable = async t => {
	const [lists, cardHistory, disabledListIDs] = await Promise.all([
		t.lists('id', 'name'),
		t.get('card', 'shared', CONSTANTS.KEY.HISTORY, []),
		t.get('board', 'shared', CONSTANTS.KEY.DISABLED_LIST_IDS, [])
	]);
	const listsMap = new Map();
	lists.forEach(list => {
		listsMap.set(list.id, list.name);
	});

	const historyGroups = new Map();
	for (let i = 0; i < cardHistory.length - 1; ++i) {
		if (!disabledListIDs.includes(cardHistory[i].list)) {
			addNewHistoryGroupEntry(historyGroups, cardHistory[i].list, new Date(cardHistory[i].since), new Date(cardHistory[i + 1].since));
		}
	}

	const lastEntry = cardHistory[cardHistory.length - 1];
	if (!disabledListIDs.includes(lastEntry.list)) {
		addNewHistoryGroupEntry(historyGroups, lastEntry.list, new Date(lastEntry.since), new Date(Date.now()));
	}

	const historyRowsGroupped = [];
	for (const [list, entry] of historyGroups.entries()) {
		const listName = listsMap.get(list) || 'Archived list';
		historyRowsGroupped.push(createGrouppedHistoryTableRows(listName, entry));
	}

	return historyRowsGroupped;
}

/**
 * Updates the given Map object with a new group entry.
 * 
 * @param {*} historyGroups - Map object
 * @param {*} listID - ID of a list which is used as a key in the Map object
 * @param {*} since - Date object, indicates when was the card moves into the actual list
 * @param {*} until Date object, indicates until when was the card present in the actual list
 */
export const addNewHistoryGroupEntry = (historyGroups, listID, since, until) => {
	const elapsedMinutes = diffInMinutes(since, until);
	const listGroup = historyGroups.get(listID);
	if (listGroup) {
		listGroup.totalElapsedMinutes += elapsedMinutes;
		const newRelevantEntry = {
			since,
			elapsedMinutes
		};
		listGroup.relevantEntries.push(newRelevantEntry);
	} else {
		const firstEntry = {
			initialDate: since,
			totalElapsedMinutes: elapsedMinutes,
			relevantEntries: [{ since, elapsedMinutes }]
		};
		historyGroups.set(listID, firstEntry);
	}
}

/**
 * Creates and returns an array of table rows for the given list. The first row of the table is collapsible
 * in case the card was moved into the list multiple times. The rest of the elements (if present) are the relevant
 * entries for that group that actually make the sum of the first one.
 * 
 * @param {*} listName - Name of the list to which the table rows will be created
 * @param {*} listEntry - Object, containing three properties: 
 *   - initialDate - Date object, indicates when was the card first moved into the list
 *   - totalElapsedMinutes - how many minutes elapsed the card in this list
 *   - relevantEntries - Array, containing objects with `since` (Date) and `elapsedMinutes` properties
 */
export const createGrouppedHistoryTableRows = (listName, listEntry) => {
	const collapsibleRow = createHistoryTableRow(listName, listEntry.initialDate, listEntry.totalElapsedMinutes, false);
	const groupRows = [collapsibleRow];
	if (listEntry.relevantEntries.length <= 1) {
		return groupRows;
	}

	collapsibleRow.className = 'collapsible';
	listEntry.relevantEntries.forEach(entry => {
		const contentRow = createHistoryTableRow(listName, entry.since, entry.elapsedMinutes, true);
		contentRow.className = 'content';
		groupRows.push(contentRow);
	});

	return groupRows;
}

/**
 * Creates and returns an HTML table row containing one history entry for a card.
 * 
 * @param {*} listName - Display name of the actual list
 * @param {*} since - Date object, indicates when the card was moved into the list 
 * @param {*} elapsedMinutes - How many minutes spent the card in the list
 * @param {*} hideFirstColumn - It will make the first column (list name) hidden in case it is set
 */
export const createHistoryTableRow = (listName, since, elapsedMinutes, hideFirstColumn) => {
	const row = document.createElement('tr');
	const listTD = document.createElement('td');
	listTD.appendChild(document.createTextNode(listName));
	if (hideFirstColumn) {
		listTD.style.visibility = 'hidden';
	}
	const sinceTD = document.createElement('td');
	sinceTD.appendChild(document.createTextNode(formatDateTime(since)));
	const elapsedTD = document.createElement('td');
	elapsedTD.appendChild(document.createTextNode(formatElapsedTime(elapsedMinutes)));
	row.appendChild(listTD);
	row.appendChild(sinceTD);
	row.appendChild(elapsedTD);
	return row;
}

export default cardBackSection;