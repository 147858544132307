const CONSTANTS = {
	KEY: {
		HISTORY: 'history',
		LIST: 'list',
		DISABLED_LIST_IDS: 'disabled_list_ids'
	},
	REFRESH_INTERVAL: 60, // 1 minute in seconds
};

export default CONSTANTS;
