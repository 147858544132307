import CONSTANTS from "../utils/constants";

/**
 * This function will be called by Trello once the mouse gets hovered over icon `...` (more) on a list.
 * Returns a menu item, which gives a possibility to the user to either enable or disable time tracking
 * on the actual list.
 * Time tracking on a list is disabled once its ID is present in an array saved into the board with
 * `DISABLED_LIST_ID` key.
 * 
 * @param {*} t - Trello API
 */
const timeTrackingListAction = async t => {
	try {
		const disabledListIDs = await t.get('board', 'shared', CONSTANTS.KEY.DISABLED_LIST_IDS, []);
		const isCurrentDisabled = disabledListIDs.find(id => id === t.getContext().list);
		return [{
			text: isCurrentDisabled ? 'Enable time tracking' : 'Disable time tracking',
			callback: isCurrentDisabled ?
				t => enableTimeTrackingCallback(t, disabledListIDs) :
				t => disableTimeTrackingCallback(t, disabledListIDs)
		}];
	} catch (e) {
		t.alert({
			message: 'Could not retrieve the disabled lists! Please try again.',
			duration: 7,
			display: 'error'
		});
		return [];
	}
};

/**
 * Removes the actual list id from the already disabled list ids array and saves that.
 * In case the operation fails, an alert window will be shown to the user with an appropriate message.
 * 
 * @param {*} t - Trello API
 * @param {*} disabledListIDs - Array, containing the already disabled list IDs.
 */
export const enableTimeTrackingCallback = async (t, disabledListIDs) => {
	try {
		const index = disabledListIDs.findIndex(id => id === t.getContext().list);
		if (index < 0) {
			throw Error('Current list id is not in the disabled ids array!');
		}
		disabledListIDs.splice(index, 1);
		await t.set('board', 'shared', CONSTANTS.KEY.DISABLED_LIST_IDS, disabledListIDs);
	} catch (e) {
		t.alert({
			message: 'Could not enable time tracking on list! Please try again.',
			duration: 7,
			display: 'error'
		});
	} finally {
		t.closePopup();
	}
};

/**
 * Shows a popup window asking the user if he/she wants to delete the existing history entries with
 * the actual list from all of the open cards.
 * 
 * @param {*} t - Trello API
 * @param {*} disabledListIDs - Array, containing the already disabled list IDs.
 */
export const disableTimeTrackingCallback = (t, disabledListIDs) => {
	t.popup({
		type: 'confirm',
		title: 'Warning! Not reversible action!',
		message: 'Would you like to delete every history entry with this list from the cards?',
		confirmText: 'Yes',
		cancelText: 'No',
		confirmStyle: 'danger',
		onConfirm: t => confirmCardHistoryDeletion(t, disabledListIDs),
		onCancel: t => { 
			saveNewDisabledList(t, disabledListIDs);
			t.closePopup();
		}
	});
};

/**
 * Gets called in case the user presses `Yes` on the question popup.
 * The method tries to delete all history entries with the actual list from every card.
 * If these operations succeed, the newly disabled list will also get saved.
 * In case of a history deletion failure, an appropriate alert window will be shown to the user.
 * 
 * @param {*} t - Trello API
 * @param {*} disabledListIDs - Array, containing the already disabled list IDs.
 */
export const confirmCardHistoryDeletion = async (t, disabledListIDs) => {
	try {
		await deleteAllRelatedHistoryEntry(t);
		saveNewDisabledList(t, disabledListIDs);
	} catch (e) {
		t.alert({
			message: 'Could not delete history entries from one of the cards! Please try again.',
			duration: 7,
			display: 'error'
		});
	} finally {
		t.closePopup();
	}
}

/**
 * Deletes all history entries with the actual list from all of the open cards on the board.
 * Every delete operation is executed as a Promise. These promises will run independently of each other.
 * 
 * The functions returns a single Promise which resolves when all of his sub promises have resolved.
 * In case one of them rejects, the returned Promise will reject right away. This means error
 * handling should be done in the caller function.
 * 
 * @param {*} t - Trello API
 */
export const deleteAllRelatedHistoryEntry = async t => {
	const cards = await t.cards('id');
	const promises = cards.map(async card => {
		const cardHistory = await t.get(card.id, 'shared', CONSTANTS.KEY.HISTORY, []);
		const cleanedHistory = cardHistory.filter(historyEntry => historyEntry.list !== t.getContext().list);
		if (cardHistory.length !== cleanedHistory.length) {
			await t.set(card.id, 'shared', CONSTANTS.KEY.HISTORY, cleanedHistory);
		}
	});
	return Promise.all(promises);
};

/**
 * Saves the newly disabled list id into the existing disabled list ids array.
 * In case the save operations fails, an alert window will be shown to the user with an appropriate message.
 * 
 * @param {*} t - Trello API
 * @param {*} disabledListIDs - Array, containing the already disabled list IDs.
 */
export const saveNewDisabledList = async (t, disabledListIDs) => {
	try {
		disabledListIDs.push(t.getContext().list);
		await t.set('board', 'shared', CONSTANTS.KEY.DISABLED_LIST_IDS, disabledListIDs);
	} catch (e) {
		t.alert({
			message: 'Could not save the disabled list! Please try again.',
			duration: 7,
			display: 'error'
		});
	}
}

export default timeTrackingListAction;